@use '../includes' as *;

.choose_station_button {
  display: flex;
  align-items: center;
  max-height: var(--navigation-height);
  margin-inline-end: auto;
  padding-inline: calc(var(--navigation-spacing-inline) / 2);

  // RWEB-8956 at the very smallest breakpoints we need to tighten this button up
  @include breakpoint($max: $xxxs) {
    button {
      padding-inline: $g12;
    }
  }

  @include breakpoint($xxxs) {
    padding-inline: var(--navigation-spacing-inline);
  }

  @include breakpoint($sm) {
    margin-inline: auto 0;
  }
}
