@use '../includes' as *;

.my_list_nav {
  @include horizontal-scroll-with-hidden-scrollbar;

  display: flex;
  position: relative;
  inset-inline-start: calc(-1 * var(--spacing-inline));
  gap: $g16;
  width: calc(100% + (2 * var(--spacing-inline)));
  margin-block-end: $g20;
  padding-block: $g20 $g12;
  padding-inline: var(--spacing-inline);
  border-block-start: $slate-border;

  &::after {
    // position: sticky elements don't work with explicit widths - they only expand to the size of
    // their content. So here we are inserting a bunch of non-breaking spaces to force the width.
    content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
    position: sticky;
    inset: 0 calc(-1 * var(--spacing-inline)) 0 auto;
    height: 42px;
    background-image: linear-gradient(
      to right,
      rgba($midnight, 0),
      $midnight 80%
    );
    pointer-events: none;
  }

  @include breakpoint($xxs) {
    &::after {
      display: none;
    }
  }

  @include breakpoint($sm) {
    gap: $g32;
  }

  @include breakpoint($md) {
    gap: $g64;
    margin-block-end: $g32;
  }

  @include breakpoint($xl) {
    gap: $g80;
  }
}

a.my_list_nav_link {
  @include h4($color: $pebble, $weight: $normal);

  display: inline-block;

  // margin prevents the focus outline from being blocked
  margin: 4px 4px 0;
  transition: font-weight 0.1s ease;
  white-space: nowrap;
  text-underline-offset: $g8;

  @include breakpoint($lg) {
    text-underline-offset: $g12;
  }

  &:hover,
  &:focus,
  &:active {
    color: $fog;
    text-decoration: none;
  }

  &[class*='is_active'] {
    color: $white;
    font-weight: $bold;
    text-decoration: underline $teal;
    text-decoration-thickness: 3px;

    @include breakpoint($lg) {
      text-decoration-thickness: 4px;
    }
  }
}
