@use '../includes' as *;

.my_list_header {
  margin-block-end: calc(var(--spacing-block) / 2);
}

.my_list_header_title {
  @include h1;

  margin-block-end: $g12;
}

.my_list_header_intro {
  @include body-copy-3($pebble);

  display: inline-flex;
  align-items: center;

  @include breakpoint($xs) {
    @include h4($color: $pebble, $weight: $normal);
  }

  svg {
    width: 34px;
    height: 34px;
    margin: 0 $g8;
    padding: 6px;
    border: 1px solid $pebble;
    border-radius: 50%;
    fill: $pebble;

    @include breakpoint($sm) {
      width: 36px;
      height: 36px;
      padding: 7px;
      border-width: 2px;
    }
  }
}
