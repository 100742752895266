@use '../includes' as *;

@mixin icons {
  width: 1em;
  height: 1em;
  margin-inline-end: $g8;
  translate: 0 0.15em;
}

.station_menu {
  position: absolute;
  inset: var(--navigation-height) 0 auto 0;
  width: 100vw;
  height: max-content;
  padding-block: $g48;
  padding-inline: $g32;
  overflow: hidden;
  transition:
    max-height var(--duration-half) ease-out,
    opacity var(--duration-half) ease-out;
  opacity: 0;
  pointer-events: none;

  @include menu-background;

  &.is_open {
    transition: opacity 0 ease-out;
    opacity: 1;
    pointer-events: auto;
  }

  @include breakpoint($utility-nav-bp) {
    inset: calc(var(--navigation-height)) -100px 0 auto;
    width: max-content;
    border-radius: 6px;
  }
}

.station_menu_item {
  display: flex;
  align-items: center;
  height: var(--navigation-height);
  margin-inline-end: auto;

  @include menu-triangle(calc(45%));

  @include breakpoint($sm) {
    flex-grow: 1;
    justify-content: center;

    .station_button {
      position: absolute;
      inset-inline-start: 50%;
      translate: -50% 0;
    }
  }

  @include breakpoint($utility-nav-bp) {
    position: relative;
    flex-grow: 0;

    .station_button {
      position: static;
      inset-inline-start: 0;
      translate: 0 0;
    }
  }
}

@include bp-utility-nav-mouse-only {
  // when we're hoving over the menu item, but _not_ the confirm station popup
  .station_menu_item:not(:has([class*='confirm_station_popup']:hover)) {
    // when the wrapper div is hovered
    &:hover,
    // or the station button is hovered
    &:has(button:hover),
    // or the station menu is hovered
    &:has(.station_menu:hover),
    // or if there is a link in the user menu that is in focus
    &:has(.station_menu:focus-within) {
      .station_menu {
        transition: opacity 0 ease-out;
        opacity: 1;
        pointer-events: auto;
      }

      &::before {
        opacity: 1;
      }
    }
  }
}

.station_menu_inner {
  display: flex;
  flex-direction: column;
  gap: $g48;

  h2 {
    @include over-title;

    font-size: $f16;
  }

  @include breakpoint($sm) {
    flex-direction: row;
    justify-content: space-between;
    gap: $g80;
  }
}

.station_menu_info {
  display: flex;
  flex-direction: column;
  gap: $g24;
  max-width: 450px;
}

.station_menu_info_title {
  display: none;

  @include breakpoint($md) {
    display: block;
  }
}

.station_menu_description {
  display: none;
  line-height: 1.5;

  @include breakpoint($md) {
    display: block;
  }
}

.station_menu_more_from_station {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $g28;
  min-width: 150px;

  h2 {
    color: $pebble;
    font-size: $f10;
    text-align: end;
  }

  @include breakpoint($sm) {
    align-items: end;
    gap: $g24;

    h2 {
      color: $fog;
    }
  }

  @include breakpoint($md) {
    gap: $g28;

    h2 {
      font-size: $f16;
    }
  }
}

.station_menu_links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $g32;
  font-size: $f12;
  font-weight: $bold;

  @include breakpoint($sm) {
    align-items: end;
    gap: $g24;
  }

  @include breakpoint($md) {
    gap: $g28;
    font-size: $f16;
  }
}

.station_menu_link {
  display: flex;
  align-items: center;

  &:hover {
    path {
      transition: fill var(--duration-half) ease-in;
      fill: $pebble;
    }
  }
}

.station_menu_logo {
  padding: unset;

  img {
    width: auto;
    max-width: 250px;
    height: auto;
    max-height: 50px;
  }
}

.station_menu_explore_more_link {
  width: max-content;
  font-size: $f12;
  font-weight: $bold;

  @include breakpoint($md) {
    font-size: $f16;
  }

  &:hover,
  &:focus-visible,
  &:active {
    path {
      transition: fill var(--duration-half) ease-in;
      fill: $pebble;
    }
  }
}

// Need to add extra specificity here to override default styles
// on the Change Station Button
button.station_menu_change_station_button {
  color: var(--link-color);
  font-size: $f12;
  font-weight: $bold;

  /* stylelint-disable-next-line no-descending-specificity */
  path {
    fill: $white;
  }

  &:hover,
  &:focus-visible,
  &:active {
    color: var(--link-active-color);

    path {
      fill: $pebble;
    }
  }

  &:focus-visible {
    outline: $focusstatelight;
  }

  @include breakpoint($md) {
    font-size: $f16;
  }
}

.my_station_icon {
  @include icons;
}

.gear_icon {
  @include icons;
}

.next_icon {
  width: 1em;
  height: 1em;
  margin-inline-start: $g8;
  fill: $white;
}
