@use '../includes' as *;

.user_menu {
  @include body-copy-1;

  position: absolute;
  // magic number right aligns menu
  inset: calc(var(--navigation-height)) -120px auto auto;
  width: min-content;

  @include menu-background;

  svg {
    width: 16px;
    height: 16px;
  }

  a {
    display: flex;
    align-items: center;
    gap: $g8;
    text-wrap: nowrap;

    &:hover,
    &:focus {
      .user_icon,
      .gear_icon {
        path {
          fill: $pebble;
        }
      }
    }
  }

  @include breakpoint($utility-nav-bp) {
    // -52px is a magic number that centers the menu
    inset: calc(var(--navigation-height)) -52px auto auto;
  }

  @include bp-utility-nav-mouse-only {
    border-radius: 6px;
  }
}

.user_menu_inner {
  display: flex;
  flex-direction: column;
  gap: $g16;
  padding: $g40 $g36 $g40 $g36;
}

.sign_out {
  font-weight: $bold;
}
