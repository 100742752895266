@use '../includes' as *;

.station_button {
  display: flex;
  align-items: center;
  padding-inline-start: var(--navigation-spacing-inline);
  border: 0;
  background: transparent;
  cursor: pointer;

  img {
    width: auto;
    max-width: 90px;
    height: auto;
    max-height: var(--navigation-image-height);

    @include breakpoint($xxxs) {
      max-width: 150px;
    }
  }
}

.up_icon,
.down_icon {
  width: 12px;
  height: auto;
  margin-inline-start: $g8;
  fill: $white;

  @include breakpoint($mdlg) {
    display: none;
  }
}
