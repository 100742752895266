@use '../includes' as *;

.donate_menu_item {
  @include menu-triangle(36px);

  position: relative;
}

.donate_link {
  display: flex;
  align-items: center;
  gap: $g8;
  font-weight: $bold;
}

.donate_icon {
  width: 22px;
  height: 18px;

  path {
    // this svg when animated feels out of sync with the text next to it
    // so we are slowing it down by 1.6 in order for it to visually feel 'right'
    transition-duration: calc(var(--duration-half) * 1.6);
    fill: $red;

    .donate_link:hover & {
      fill: rgba($red, 0.7);
    }
  }
}

.donate_menu {
  display: none;
  position: absolute;
  // -146 is a magic number that centers this drop down
  // under the "My List" link
  inset: calc(var(--navigation-height)) -146px auto auto;
  width: 705px;
  transition: opacity var(--duration-half) ease-in;
  border-radius: 6px;
  opacity: 0;
  text-align: center;
  // fix for preventing hover happening when near but not on the menu
  pointer-events: none;

  @include menu-background;
}

.donate_menu_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $g20;
  padding: $g52 $g48 $g52 376px;
}

.donate_menu_intro {
  @include body-copy-1;
}

// woman with plant illustration
.donate_illustration {
  position: absolute;
  inset: auto auto 0 $g48;
  width: 285px;
  height: 223px;
}

@include bp-utility-nav-mouse-only {
  .donate_menu {
    display: block;
  }

  .donate_menu_item {
    // when the wrapper div is hovered
    &:hover,
    // or the donate button is hovered
    &:has(button:hover),
    // or the donate menu is hovered
    &:has(.donate_menu:hover),
    // or if there is a link in the user menu that is in focus
    &:has(.donate_menu:focus-within) {
      // then we want to make see the triangle
      &::before {
        opacity: 1;
      }

      // and show the donate menu
      .donate_menu {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
