@use '../includes' as *;

.all_genres_menu {
  @include body-copy-1;

  display: flex;
  position: absolute;
  flex-direction: column;
  gap: $g12;
  width: max-content;
  padding-block: 35px;
  padding-inline: 55px;
  transition: opacity var(--duration-half) ease-in;
  border-radius: $radius-large;
  font-size: $f14;
  pointer-events: none;

  @include menu-background;

  h2 {
    @include over-title;

    font-size: $f14;
  }

  a {
    max-width: max-content;
    margin-block-start: $g8;
    font-size: $f14;
  }
}

.all_genres_menu__genres_wrapper {
  display: flex;
  column-gap: $g60;
}

.all_genres_menu__left,
.all_genres_menu__right {
  display: flex;
  flex-direction: column;
  gap: $g12;
}

.all_genres_menu__right {
  svg {
    height: 1.3em;
    margin-inline-end: $g4;
    transform: translateY(2px);
  }
}
