@use '../includes' as *;

.navigation {
  position: sticky;
  inset-block-start: 0;
  // Bring it above the show posters in shows landing
  // And above the hovered show posters in My List
  z-index: 23;

  @include breakpoint($configurable-badge-bp) {
    position: static;
  }
}

body:has([class*='urgent_message_badge']) .navigation {
  position: relative;
}
