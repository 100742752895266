@use 'sass:color';

// Colors
// https://www.figma.com/file/CebVm3fWixvG14NMxAQXEm/Brand-Assets?node-id=0%3A1
// Dark Mode additions
// https://www.figma.com/file/92Xdrorhm4L4FyagycOgDp/Dark-Mode?node-id=177%3A291
// Core colors
$pbs-blue: #2638c4;
$medium-blue: #0f1e8c;
$navy-blue: #0a145a;
$white: #fff;
$passport-blue: #5680ff;

// Brand Neutrals
$onyx: #040613; // rgb(4, 6, 19)
$midnight: #000525; // rgb(0, 5, 37) used in many, many places
$charcoal: #1e2030; // rgb(30, 32, 48)
$shadow: #363846; // rgb(54, 56, 70)
$paynes-gray: #171b33; // rgb(23, 27, 51);
$blue-gray: #3d4a6b; // rgb(61, 74, 107)
$slate: #5c6e85; // rgb(92, 110, 133)
$pebble: #94a1b2; // rgb(148, 161, 178)
$moon: #c0cbda; // rgb(192, 203, 218)
$fog: #eef0f5; // rgb(238, 240, 245)
$cloud: #f6f8fa; // rgb(246, 248, 250)

// Note - these variations of $pbs-blue are only used in generating gradients
$pbs-blue-darken-20: color.mix($midnight, $pbs-blue, 20%); // #1e2d9d
$pbs-blue-lighten-10: color.mix($white, $pbs-blue, 10%); // #3c4cca

// Hues
$teal: #46d4cd;
$teal-darken-20: color.mix($midnight, $teal, 20%); // #38aaa4
$teal-darken-10: color.mix($midnight, $teal, 10%); // #3fbfb9
$teal-lighten-10: color.mix($white, $teal, 10%); // #59d8d2
$teal-lighten-20: color.mix($white, $teal, 20%); // #6bddd7

$yellow: #ffcf00;
$yellow-darken-20: color.mix($midnight, $yellow, 20%); // #cca600
$yellow-darken-10: color.mix($midnight, $yellow, 10%); // #e6ba00
$yellow-lighten-10: color.mix($white, $yellow, 10%); // #ffd41a
$yellow-lighten-20: color.mix($white, $yellow, 20%); // #ffd933

$coral: #fe704e;
$coral-darken-20: color.mix($midnight, $coral, 20%); // #cb5a3e
$coral-darken-10: color.mix($midnight, $coral, 10%); // #e56546
$coral-lighten-10: color.mix($white, $coral, 10%); // #fe7e60
$coral-lighten-20: color.mix($white, $coral, 20%); // #fe8d71

$light-blue: #486cd8;
$light-blue-darken-20: color.mix($midnight, $light-blue, 20%); // #3a56ad
$light-blue-darken-10: color.mix($midnight, $light-blue, 10%); // #4161c2
$light-blue-lighten-10: color.mix($white, $light-blue, 10%); // #5a7bdc
$light-blue-lighten-20: color.mix($white, $light-blue, 20%); // #6d89e0

$red: #d6383a;
$red-darken-20: color.mix($midnight, $red, 20%); // #ab2d2e
$red-darken-10: color.mix($midnight, $red, 10%); // #c13234
$red-lighten-10: color.mix($white, $red, 10%); // #da4c4e
$red-lighten-20: color.mix($white, $red, 20%); // #de6061

$green: #087b45;
$green-darken-20: color.mix($midnight, $green, 20%); // #066237
$green-darken-10: color.mix($midnight, $green, 10%); // #076f3e
$green-lighten-10: color.mix($white, $green, 10%); // #218858
$green-lighten-20: color.mix($white, $green, 20%); // #39956a

// Note we have $purple: #6a1b9a as well, but don't use it on .org

// 3rd party brand colors
$facebook-blue: #0866ff;
$google-blue: #4285f4;
$yahoo-purple: #400191;
$pinterest-red: #b8242a;
$instagram-blue: #4890c7;
$youtube-red: #cd201f;
$linkedin-blue: #0077b5;

// Functional Color Variables
$divider-color: rgba(#c1c1c1, 0.4);

// border color taken from Figma
$season-picker-border-color: #60738a;
