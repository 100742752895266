@use '../../../components/includes' as *;

.activate_logo {
  position: absolute;
  inset-block-start: 12px;
  inset-inline-start: var(--spacing-inline);

  svg {
    width: auto;
    height: var(--navigation-image-height);
  }
}

.station_logo {
  width: auto;
  height: var(--navigation-image-height);
}

.activate_content {
  display: flex;
  position: relative;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - (#{$nav-border-width} + var(--activate-footer)));
}
