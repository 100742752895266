@use '../includes' as *;

.user {
  display: flex;
  align-items: center;
}

.profile_icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile_passport_compass {
  width: 18px;
  height: 18px;
  margin-inline-start: -#{$g8};
}

.profile_initial_thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $light-blue;
  color: $white;
  font-weight: $bold;
}
