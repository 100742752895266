@use '../includes' as *;

@mixin mobile-menu-item {
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  gap: $g16;
  margin-block-end: $g36;
  font-size: $f18;
}

@mixin menu-slide-in {
  transition-property: opacity, translate;
  transition-duration: var(--duration-half);
  transition-timing-function: ease-out;
  opacity: 0;
  translate: $g32 0;

  .is_open & {
    opacity: 1;
    translate: 0 0;
  }
}

// prevent scrolling in the background when the mobile menu is open
body:has(.mobile_menu.is_open) {
  @include breakpoint($max: $sm) {
    overflow: hidden;
  }
}

.mobile_menu_wrapper {
  @include bp-utility-nav-mouse-only {
    display: none;
  }
}

.mobile_menu {
  position: absolute;
  inset: var(--navigation-height) 0 0;
  width: 100%;
  height: calc(100vh - var(--navigation-height));
  // Okay, so this is weird. Safari won't let -webkit-backdrop-filter work when the max height is 0.
  // (-webkit-backdrop-filter is used in the menu-background mixin)
  // So, we set it to 1px so the div is "visible" for Safari's rendering engine.
  // However, we then need to set the opacity to 0 so there isn't a weird line hanging out,
  // and then toggle the opacity when the menu is open.
  max-height: 1px;
  overflow: hidden auto;
  transition:
    max-height var(--duration-half) ease-out,
    opacity var(--duration-half) ease-out;
  opacity: 0;

  @include menu-background;

  &.is_open {
    max-height: 2000px;
    transition: opacity 0 ease-out;
    opacity: 1;
  }

  @include breakpoint($sm) {
    inset-inline-start: auto;
    width: 320px;
  }

  svg {
    max-width: 20px;
  }

  a {
    &:hover,
    &:focus-visible {
      color: $pebble;
      text-decoration: none;

      path,
      circle {
        fill: currentcolor;
      }
    }
  }
}

.mobile_menu_inner {
  padding: $g32 $g20 $g72 $g20;

  @include breakpoint($sm) {
    padding: $g44 $g40;
  }
}

.genres_toggle {
  @include mobile-menu-item;
  @include menu-slide-in;
}

.genres_button {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  text-align: start;
}

button.back_button {
  padding: 0;
  border: none;
  background: none;
  text-align: start;

  svg {
    width: 8px;
    max-width: 100%;
    height: 14px;
    fill: $white;
  }
}

.links {
  transition-property: transform, opacity;
  transition-duration: var(--duration);
  transition-timing-function: ease-out;

  .genres_open & {
    transform: translateX(-100%);
    opacity: 0;
  }

  li {
    @include menu-slide-in;

    .is_open & {
      // these animation delays give the 'staggered slide in' effect
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: calc(var(--duration-half) * 0.5 * #{$i});
        }
      }
    }
  }

  li:not(.personal) a {
    @include mobile-menu-item;

    svg {
      margin-inline: auto;
    }
  }
}

.genre_links {
  position: absolute;
  inset-block-start: 79px; // magic number based on figma
  width: 100%;
  max-height: 0;
  padding-block-end: 200px;
  padding-inline-start: 0;
  overflow-y: auto;
  transform: translateX(100%);
  transition-property: transform, opacity;
  transition-duration: var(--duration);
  transition-timing-function: ease-out;
  opacity: 0;

  .genres_open & {
    max-height: calc(100vh - 64px);
    transform: translateX(0%);
    opacity: 1;
  }
}

.mobile_menu_genre_nav_link {
  display: flex;
  align-items: center;
  gap: $g8;
  margin-block-end: $g8;
  // the use of 26px for the left padding is weird,
  // but it's due to the odd border size
  padding: $g8 $g8 $g8 26px;
  border-inline-start: 7px solid transparent;
  font-size: $f16;

  &[class*='is_active'] {
    border-color: $teal;
  }
}

// icons
.live_tv_icon {
  width: 12px;
  height: 12px;
}

.my_station_icon {
  width: 16px;
  height: 20px;
}

.passport_compass {
  width: 20px;
  height: 20px;
}
// mobile menu profile
.mobile_menu_profile {
  display: flex;
  align-items: center;
  column-gap: $g12;
  max-width: 100%;
  translate: 0 -12px;

  .name_sign_out {
    display: flex;
    flex-direction: column;
    gap: $g4;
    width: min-content;
    max-width: calc(100vw - 140px);
    translate: 0 13px;
  }

  .profile_name {
    @include h5;

    grid-area: name;
    overflow: hidden;
    font-size: $f16;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sign_out {
    @include body-copy-1;

    grid-area: signout;
    font-size: $f16;
  }

  .gear_icon {
    width: 20px;
    height: 20px;
    translate: 0 5px;
  }
}
