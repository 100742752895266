@use '../includes' as *;

.sign_in_or_profile,
.sign_in_or_profile_svp {
  display: none;
  position: relative;
  align-items: center;
  // magic number from figma
  min-width: 80px;
  height: var(--navigation-height);
  padding-inline: var(--navigation-spacing-inline) 0;
  transition-property: min-width, opacity;
  transition-duration: var(--duration-half);
  transition-timing-function: ease-out;
  opacity: 0;

  @include breakpoint($sm) {
    display: flex;
  }
}

.sign_in_or_profile_svp {
  margin-inline-start: auto;
}

.sign_in {
  opacity: 1;
}

.profile {
  min-width: 0;
  opacity: 1;
}

// colors, positioning, etc, is in
// SignInMenu.module.scss & UserMenu.module.scss
.sign_in_menu,
.user_menu {
  display: none;
  transition: opacity var(--duration-half) ease-in;
  opacity: 0;
  pointer-events: none;
}

.user_button {
  position: relative;
  height: 100%;
  transition: background-color var(--duration-half) ease-in;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.user_image_inital {
  transition: opacity var(--duration-half) ease-in;
}

.close_icon {
  position: absolute;
  inset: 50%;
  width: 20px;
  height: 20px;
  transition: opacity var(--duration-half) ease-in;
  opacity: 0;
  fill: $fog;
  translate: -50% -50%;
}

@include breakpoint($sm) {
  .user_menu {
    display: block;
  }

  .user_menu_is_open {
    .user_button {
      background-color: $blue-gray;
    }

    .user_menu,
    .close_icon {
      opacity: 1;
      pointer-events: auto;
    }

    .user_image_inital {
      opacity: 0;
    }
  }
}

// this bit of CSS replaces a lot of squirelly JavaScript
// it happens only above $md on mouse devices
@include bp-utility-nav-mouse-only {
  .sign_in_menu,
  .user_menu {
    display: block;
  }

  .sign_in_or_profile,
  .sign_in_or_profile_svp {
    &.sign_in {
      @include menu-triangle(36px);
    }

    &.profile {
      @include menu-triangle(18px);
    }

    // when the wrapper div is hovered
    &:hover,
    // or the sign in button is hovered
    &:has(button:hover),
    // or the sign in menu is hovered
    &:has(.sign_in_menu:hover),
    // shared with the user menu
    &:has(.user_menu:hover),
    // or if there is a link in the user menu that is in focus
    &:has(.user_menu:focus-within) {
      // then we want to make see the triangle
      &::before {
        opacity: 1;
      }

      // and show the sign in menu
      .sign_in_menu,
      .user_menu {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
