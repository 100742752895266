@use '../../../components/includes' as *;

.activate_page {
  position: relative;
  height: calc(100dvh);
  border-block-start: $nav-border-width solid $pbs-blue;
  background: $midnight;

  &:has(input:focus) {
    height: 100vh;
  }
}

.activate_background_image {
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
  opacity: 0.2;
  background: $midnight;
  pointer-events: none;

  img {
    width: 100vw;
    height: calc(100dvh);
    object-fit: cover;
  }
}

.activate_footer {
  display: flex;
  position: absolute;
  inset-block-end: 0;
  z-index: 10;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: $g8;
  width: 100%;
  min-height: var(--activate-footer);
  padding-block: $g16;
  padding-inline: var(--spacing-inline);
  background-color: $paynes-gray;

  @include body-copy-3;

  a {
    margin-inline-start: $g4;
    transition: opacity var(--duration-half) ease-in;
    color: $yellow;
    font-weight: $bold;

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }

  @include breakpoint($sm) {
    flex-direction: row;
    align-items: center;
    gap: $g40;

    @include body-copy-1;
  }
}
