@use '../../includes' as *;

@keyframes vanish {
  to {
    display: none;
    transform: translateY(10px);
    opacity: 0;
  }
}

.confirm_station_popup {
  @include bg-blur($blue-gray, $opacity: 0.8);

  display: inline-flex;
  position: absolute;
  inset: calc(var(--navigation-height) - $g4) auto auto 50%;
  z-index: 20;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: $g8;
  width: max-content;
  max-width: calc(100% - (var(--spacing-inline) * 2));
  padding: $g12 $g28;
  border-radius: 6px;
  translate: -50% 0;

  // https://developer.chrome.com/blog/entry-exit-animations
  // you can animate to display: none with keyframes
  // @TODO in future, when Firefox support is better, update to use
  // @starting-style as it gives you more control
  &[hidden] {
    animation: vanish var(--duration) forwards;
  }

  @include breakpoint($sm) {
    flex-wrap: nowrap;
    min-width: min-content;
  }
}

.confirm_station_name {
  font-size: $f12;
  text-align: center;
  white-space: nowrap;

  @include breakpoint($utility-nav-bp) {
    font-size: $f14;
  }
}

.change_station_button {
  @include h6;

  display: inline-flex;
  align-items: center;
  gap: $g4;
  border: 0;
  background-color: transparent;
  color: $fog;
  font-size: $f12;
  cursor: pointer;

  @include breakpoint($utility-nav-bp) {
    font-size: $f14;
  }

  svg {
    width: 10px;
    height: 12px;
    fill: currentcolor;
  }
}
