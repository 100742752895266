@use '../includes' as *;

.mobile_menu_button {
  display: flex;
  align-items: center;
  height: var(--navigation-height);
  padding: var(--navigation-spacing-inline);
  border: none;
  background-color: transparent;

  @include breakpoint($sm) {
    &.is_open {
      background-color: $blue-gray;
    }
  }

  @include breakpoint($utility-nav-bp) {
    display: none;
  }
}

// hamburger icon animation inspired by
// https://codepen.io/designcouch/pen/ExvwPY
.hamburger {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  translate: 0 3px;
}

.hamburger_line {
  position: absolute;
  inset-inline: 0;
  width: 100%;
  height: 2px;
  transition-property: width, rotate, translate;
  transition-duration: var(--duration-half);
  border-radius: 2px;
  background-color: $white;
  // we need to disable stylelint because it's not happy
  // about the way we're using nth-child and descending specificity
  /* stylelint-disable */
  &:nth-child(1) {
    inset-block: 0;

    .is_open & {
      translate: 10px 7px;
      width: 0;
    }
  }

  &:nth-child(2),
  &:nth-child(3) {
    inset-block: 7px;

    .is_open & {
      rotate: 45deg;
      width: 28px;
      translate: -5px 0;
    }
  }

  &:nth-child(3) {
    .is_open & {
      rotate: -45deg;
    }
  }

  &:nth-child(4) {
    inset-block: 14px;

    .is_open & {
      translate: 10px -7px;
      width: 0;
    }
  }
  /* stylelint-enable */
}
