@use '../../includes/' as *;

.gear_icon {
  width: 1em;
  height: 1em;
  margin-inline-end: $g8;
  translate: 0 0.15em;

  path {
    fill: $pebble;
  }
}

.change_station_button {
  all: unset;
  width: max-content;
  border: 0;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;

  @include body-copy-3($pebble);

  @include breakpoint($sm) {
    @include body-copy-2($pebble);
  }

  &:hover,
  &:focus-visible,
  &:active {
    transition: color var(--duration-half) ease-in;
    color: $fog;

    path {
      transition: fill var(--duration-half) ease-in;
      fill: $fog;
    }
  }

  &:focus-visible {
    outline: $focusstatelight;
  }
}
